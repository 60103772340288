import { createReducer } from "@reduxjs/toolkit";
import {
  Lotto,
  Play,
  PlayType,
  Method,
  Option,
  TypeBet,
  reset,
  selectionNumbers,
  typeInput,
  setIssue,
  setLotto,
  setPlay,
  setType,
  setMultiples,
  clearSelections,
} from "./actions";
import { VietnamLottery, SouthernLottery, NorthernLottery } from "./vietnam";

export interface LottoState {
  readonly lotto: Lotto;
  readonly issue?: string;
  readonly play: Play;
  readonly independentType: TypeBet;
  readonly method?: Method;
  readonly typedNumbers: String[][];
  readonly multiples: number;
  readonly betCount: number;
  readonly betAmount: number;

  readonly [Play.ALL_RESULT]: {
    types: TypeBet[];
  };

  readonly [Play.PARLAY_BET]: {
    types: TypeBet[];
  };

  readonly [Play.SCORE]: {
    types: TypeBet[];
  };

  readonly [Play.FIRST_LAST]: {
    types: TypeBet[];
  };

  readonly [Play.THREE_NUMBERS]: {
    types: TypeBet[];
  };

  readonly [Play.FOUR_NUMBERS]: {
    types: TypeBet[];
  };

  readonly [Play.RESULT_LOSS]: {
    types: TypeBet[];
  };

  readonly [Lotto.SOUTHERN_HO_CHI_MINH]: VietnamLottery;
  readonly [Lotto.SOUTHERN_DONG_THAP]: VietnamLottery;
  readonly [Lotto.SOUTHERN_CA_MAU]: VietnamLottery;
  readonly [Lotto.SOUTHERN_BEN_TRE]: VietnamLottery;
  readonly [Lotto.SOUTHERN_VUNG_TAU]: VietnamLottery;
  readonly [Lotto.SOUTHERN_BAC_LIEU]: VietnamLottery;
  readonly [Lotto.SOUTHERN_DONG_NAI]: VietnamLottery;
  readonly [Lotto.SOUTHERN_CAN_THO]: VietnamLottery;
  readonly [Lotto.SOUTHERN_SOC_TRANG]: VietnamLottery;
  readonly [Lotto.SOUTHERN_TAY_NINH]: VietnamLottery;
  readonly [Lotto.SOUTHERN_AN_GIANG]: VietnamLottery;
  readonly [Lotto.SOUTHERN_BINH_THUAN]: VietnamLottery;
  readonly [Lotto.SOUTHERN_VINH_LONG]: VietnamLottery;
  readonly [Lotto.SOUTHERN_BINH_DUONG]: VietnamLottery;
  readonly [Lotto.SOUTHERN_TRA_VINH]: VietnamLottery;
  readonly [Lotto.SOUTHERN_LONG_AN]: VietnamLottery;
  readonly [Lotto.SOUTHERN_BINH_PHUOC]: VietnamLottery;
  readonly [Lotto.SOUTHERN_HAU_GIANG]: VietnamLottery;
  readonly [Lotto.SOUTHERN_TIEN_GIANG]: VietnamLottery;
  readonly [Lotto.SOUTHERN_KIEN_GIANG]: VietnamLottery;
  readonly [Lotto.SOUTHERN_DA_LAT]: VietnamLottery;

  readonly [Lotto.CENTRAL_HUE]: VietnamLottery;
  readonly [Lotto.CENTRAL_PHU_YEN]: VietnamLottery;
  readonly [Lotto.CENTRAL_DAK_LAK]: VietnamLottery;
  readonly [Lotto.CENTRAL_QUANG_NAM]: VietnamLottery;
  readonly [Lotto.CENTRAL_DA_NANG]: VietnamLottery;
  readonly [Lotto.CENTRAL_KHANH_HOA]: VietnamLottery;
  readonly [Lotto.CENTRAL_BINH_DINH]: VietnamLottery;
  readonly [Lotto.CENTRAL_QUANG_TRI]: VietnamLottery;
  readonly [Lotto.CENTRAL_QUANG_BINH]: VietnamLottery;
  readonly [Lotto.CENTRAL_GIA_LAI]: VietnamLottery;
  readonly [Lotto.CENTRAL_NINH_THUAN]: VietnamLottery;
  readonly [Lotto.CENTRAL_QUANG_NGAI]: VietnamLottery;
  readonly [Lotto.CENTRAL_DAK_NONG]: VietnamLottery;
  readonly [Lotto.CENTRAL_KOM_TUM]: VietnamLottery;

  readonly [Lotto.NORTHERN]: VietnamLottery;
}

const initialState: LottoState = {
  lotto: Lotto.NORTHERN,
  issue: undefined,
  play: Play.ALL_RESULT,
  independentType: {
    name: PlayType.ALL_RESULT_TWO_NUMBERS,
    descriptions: "ALL_RESULT_TWO_NUMBERS_DESCRIPTIONS",
    odds: 98.5,
    amount: 27,
    length: 2,
    selections: [[], []],
    methods: {
      [Method.CHOOSE]: {
        selections: [Option.ROUND_3, Option.ROUND_4],
      },
      [Method.TYPE]: {
        selections: [
          Option.RANDOM_10,
          Option.RANDOM_20,
          Option.RANDOM_30,
          Option.RANDOM_40,
          Option.RANDOM_50,
          Option.DOUBLE_EQUALS,
          Option.EVEN,
          Option.ODD,
          Option.BIG,
          Option.SMALL,
          Option.TOP_1,
          Option.TOP_2,
          Option.TOP_5,
          Option.TOP_10,
          Option.TOP_20,
        ],
      },
      [Method.FAST_CHOOSE]: {
        selections: [
          Option.RANDOM_10,
          Option.RANDOM_20,
          Option.RANDOM_30,
          Option.RANDOM_40,
          Option.RANDOM_50,
          Option.DOUBLE_EQUALS,
          Option.EVEN,
          Option.ODD,
          Option.BIG,
          Option.SMALL,
          Option.TOP_1,
          Option.TOP_2,
          Option.TOP_5,
          Option.TOP_10,
          Option.TOP_20,
        ],
      },
    },
  },
  typedNumbers: [[], []],
  multiples: 1,
  betCount: 0,
  betAmount: 0,

  [Play.ALL_RESULT]: {
    types: [
      {
        name: PlayType.ALL_RESULT_TWO_NUMBERS,
        descriptions: "ALL_RESULT_TWO_NUMBERS_DESCRIPTIONS",
        odds: 98.5,
        amount: 27,
        length: 2,
        selections: [[], []],
        methods: {
          [Method.CHOOSE]: {
            selections: [Option.ROUND_3, Option.ROUND_4],
          },
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
        },
      },
      {
        name: PlayType.ALL_RESULT_TWO_FIRST_NUMBERS,
        descriptions: "ALL_RESULT_TWO_FIRST_NUMBERS_DESCRIPTIONS",
        odds: 98.5,
        amount: 23,
        length: 2,
        selections: [[], []],
        methods: {
          [Method.CHOOSE]: {
            selections: [Option.ROUND_3, Option.ROUND_4],
          },
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
        },
      },
      {
        name: PlayType.ALL_RESULT_TWO_NUMBERS_1ST_PRIZES,
        descriptions: "ALL_RESULT_TWO_FIRST_NUMBERS_DESCRIPTIONS",
        odds: 3.649,
        amount: 1,
        length: 2,
        selections: [[], []],
        methods: {
          [Method.CHOOSE]: {
            selections: [Option.ROUND_3, Option.ROUND_4],
          },
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
        },
      },
      {
        name: PlayType.ALL_RESULT_THREE_NUMBERS,
        descriptions: "ALL_RESULT_THREE_NUMBERS_DESCRIPTIONS",
        odds: 960,
        amount: 23,
        length: 3,
        selections: [[], [], []],
        methods: {
          [Method.CHOOSE]: {
            selections: [Option.ROUND_2, Option.ROUND_3, Option.ROUND_4],
          },
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
        },
      },
      {
        name: PlayType.ALL_RESULT_FOUR_NUMBERS,
        descriptions: "ALL_RESULT_FOUR_NUMBERS_DESCRIPTIONS",
        odds: 8880,
        amount: 20,
        length: 4,
        selections: [[], [], [], []],
        methods: {
          [Method.CHOOSE]: {
            selections: [
              Option.ROUND_1,
              Option.ROUND_2,
              Option.ROUND_3,
              Option.ROUND_4,
            ],
          },
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
          [Method.FAST_CHOOSE]: undefined,
        },
      },
    ],
  },

  [Play.PARLAY_BET]: {
    types: [
      {
        name: PlayType.PARLAY_BET_DOUBLE,
        descriptions: "PARLAY_BET_DOUBLE_DESCRIPTIONS",
        odds: 16,
        amount: 1,
        length: 1,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: undefined,
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_1,
              Option.RANDOM_2,
              Option.RANDOM_3,
              Option.RANDOM_5,
              Option.RANDOM_10,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [Option.RANDOM_1],
          },
        },
      },
      {
        name: PlayType.PARLAY_BET_TREBLE,
        descriptions: "PARLAY_BET_TREBLE_DESCRIPTIONS",
        odds: 65,
        amount: 1,
        length: 1,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: undefined,
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_1,
              Option.RANDOM_2,
              Option.RANDOM_3,
              Option.RANDOM_5,
              Option.RANDOM_10,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [Option.RANDOM_1],
          },
        },
      },
      {
        name: PlayType.PARLAY_BET_FOLD4,
        descriptions: "PARLAY_BET_FOLD4_DESCRIPTIONS",
        odds: 180,
        amount: 1,
        length: 1,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: undefined,
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_1,
              Option.RANDOM_2,
              Option.RANDOM_3,
              Option.RANDOM_5,
              Option.RANDOM_10,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [Option.RANDOM_1],
          },
        },
      },
    ],
  },

  [Play.SCORE]: {
    types: [
      {
        name: PlayType.SCORE_SPECIAL_PRIZE,
        descriptions: "SCORE_SPECIAL_PRIZE_DESCRIPTIONS",
        odds: 98,
        amount: 1,
        length: 2,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: {
            selections: [Option.ROUND_3, Option.ROUND_4],
          },
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
        },
      },
      {
        name: PlayType.SCORE_SPECIAL_TOPICS,
        descriptions: "SCORE_SPECIAL_TOPICS_DESCRIPTIONS",
        odds: 99,
        amount: 1,
        length: 2,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: {
            selections: [Option.ROUND_3, Option.ROUND_4],
          },
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
        },
      },
      {
        name: PlayType.SCORE_SPECIAL_HEADLINE,
        descriptions: "SCORE_SPECIAL_HEADLINE_DESCRIPTIONS",
        odds: 99,
        amount: 1,
        length: 2,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: {
            selections: [Option.ROUND_3, Option.ROUND_4],
          },
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
        },
      },
      {
        name: PlayType.SCORE_SEVENTH_PRIZE,
        descriptions: "SCORE_SEVENTH_PRIZE_DESCRIPTIONS",
        odds: 98,
        amount: 4,
        length: 2,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: {
            selections: [Option.ROUND_3, Option.ROUND_4],
          },
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
        },
      },
      {
        name: PlayType.SCORE_FIRST_PRIZE,
        descriptions: "SCORE_FIRST_PRIZE_DESCRIPTIONS",
        odds: 98,
        amount: 1,
        length: 2,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: {
            selections: [Option.ROUND_3, Option.ROUND_4],
          },
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
        },
      },
    ],
  },

  [Play.FIRST_LAST]: {
    types: [
      {
        name: PlayType.FIRST_LAST_FIRST,
        descriptions: "FIRST_LAST_FIRST_DESCRIPTIONS",
        odds: 98,
        amount: 10,
        length: 1,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: {
            selections: [Option.ROUND_3],
          },
          [Method.TYPE]: undefined,
          [Method.FAST_CHOOSE]: undefined,
        },
      },
      {
        name: PlayType.FIRST_LAST_LAST,
        descriptions: "FIRST_LAST_LAST_DESCRIPTIONS",
        odds: 98,
        amount: 10,
        length: 1,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: {
            selections: [Option.ROUND_4],
          },
          [Method.TYPE]: undefined,
          [Method.FAST_CHOOSE]: undefined,
        },
      },
    ],
  },

  [Play.THREE_NUMBERS]: {
    types: [
      {
        name: PlayType.THREE_NUMBERS_TREBLE,
        descriptions: "THREE_NUMBERS_TREBLE_DESCRIPTIONS",
        odds: 960,
        amount: 3,
        length: 3,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: {
            selections: [Option.ROUND_2, Option.ROUND_3, Option.ROUND_4],
          },
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
        },
      },
      {
        name: PlayType.THREE_NUMBERS_FIRST_LAST,
        descriptions: "THREE_NUMBERS_FIRST_LAST_DESCRIPTIONS",
        odds: 960,
        amount: 4,
        length: 3,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: {
            selections: [Option.ROUND_2, Option.ROUND_3, Option.ROUND_4],
          },
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
        },
      },
      {
        name: PlayType.THREE_NUMBERS_FIRST_PRIZE,
        descriptions: "THREE_NUMBERS_FIRST_PRIZE_DESCRIPTIONS",
        odds: 960,
        amount: 1,
        length: 3,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: {
            selections: [Option.ROUND_2, Option.ROUND_3, Option.ROUND_4],
          },
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
        },
      },
      {
        name: PlayType.THREE_NUMBERS_SPECIAL_PRIZE,
        descriptions: "THREE_NUMBERS_SPECIAL_PRIZE_DESCRIPTIONS",
        odds: 960,
        amount: 1,
        length: 3,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: {
            selections: [Option.ROUND_2, Option.ROUND_3, Option.ROUND_4],
          },
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
        },
      },
    ],
  },

  [Play.FOUR_NUMBERS]: {
    types: [
      {
        name: PlayType.FOUR_NUMBERS_SPECIAL_PRIZE,
        descriptions: "FOUR_NUMBERS_SPECIAL_PRIZE_DESCRIPTIONS",
        odds: 8880,
        amount: 1,
        length: 4,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: {
            selections: [
              Option.ROUND_1,
              Option.ROUND_2,
              Option.ROUND_3,
              Option.ROUND_4,
            ],
          },
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
          [Method.FAST_CHOOSE]: undefined,
        },
      },
    ],
  },

  [Play.RESULT_LOSS]: {
    types: [
      {
        name: PlayType.RESULT_LOSS_4,
        descriptions: "RESULT_LOSS_4_DESCRIPTIONS",
        odds: 2.3,
        amount: 1,
        length: 1,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: undefined,
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_1,
              Option.RANDOM_2,
              Option.RANDOM_3,
              Option.RANDOM_5,
              Option.RANDOM_10,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [Option.RANDOM_1],
          },
        },
      },
      {
        name: PlayType.RESULT_LOSS_8,
        descriptions: "RESULT_LOSS_8_DESCRIPTIONS",
        odds: 8,
        amount: 1,
        length: 1,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: undefined,
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_1,
              Option.RANDOM_2,
              Option.RANDOM_3,
              Option.RANDOM_5,
              Option.RANDOM_10,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [Option.RANDOM_1],
          },
        },
      },
      {
        name: PlayType.RESULT_LOSS_10,
        descriptions: "RESULT_LOSS_10_DESCRIPTIONS",
        odds: 12,
        amount: 1,
        length: 1,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: undefined,
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_1,
              Option.RANDOM_2,
              Option.RANDOM_3,
              Option.RANDOM_5,
              Option.RANDOM_10,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [Option.RANDOM_1],
          },
        },
      },
    ],
  },

  [Lotto.SOUTHERN_HO_CHI_MINH]: SouthernLottery,
  [Lotto.SOUTHERN_HO_CHI_MINH]: SouthernLottery,
  [Lotto.SOUTHERN_DONG_THAP]: SouthernLottery,
  [Lotto.SOUTHERN_CA_MAU]: SouthernLottery,
  [Lotto.SOUTHERN_BEN_TRE]: SouthernLottery,
  [Lotto.SOUTHERN_VUNG_TAU]: SouthernLottery,
  [Lotto.SOUTHERN_BAC_LIEU]: SouthernLottery,
  [Lotto.SOUTHERN_DONG_NAI]: SouthernLottery,
  [Lotto.SOUTHERN_CAN_THO]: SouthernLottery,
  [Lotto.SOUTHERN_SOC_TRANG]: SouthernLottery,
  [Lotto.SOUTHERN_TAY_NINH]: SouthernLottery,
  [Lotto.SOUTHERN_AN_GIANG]: SouthernLottery,
  [Lotto.SOUTHERN_BINH_THUAN]: SouthernLottery,
  [Lotto.SOUTHERN_VINH_LONG]: SouthernLottery,
  [Lotto.SOUTHERN_BINH_DUONG]: SouthernLottery,
  [Lotto.SOUTHERN_TRA_VINH]: SouthernLottery,
  [Lotto.SOUTHERN_LONG_AN]: SouthernLottery,
  [Lotto.SOUTHERN_BINH_PHUOC]: SouthernLottery,
  [Lotto.SOUTHERN_HAU_GIANG]: SouthernLottery,
  [Lotto.SOUTHERN_TIEN_GIANG]: SouthernLottery,
  [Lotto.SOUTHERN_KIEN_GIANG]: SouthernLottery,
  [Lotto.SOUTHERN_DA_LAT]: SouthernLottery,

  [Lotto.CENTRAL_HUE]: SouthernLottery,
  [Lotto.CENTRAL_PHU_YEN]: SouthernLottery,
  [Lotto.CENTRAL_DAK_LAK]: SouthernLottery,
  [Lotto.CENTRAL_QUANG_NAM]: SouthernLottery,
  [Lotto.CENTRAL_DA_NANG]: SouthernLottery,
  [Lotto.CENTRAL_KHANH_HOA]: SouthernLottery,
  [Lotto.CENTRAL_BINH_DINH]: SouthernLottery,
  [Lotto.CENTRAL_QUANG_TRI]: SouthernLottery,
  [Lotto.CENTRAL_QUANG_BINH]: SouthernLottery,
  [Lotto.CENTRAL_GIA_LAI]: SouthernLottery,
  [Lotto.CENTRAL_NINH_THUAN]: SouthernLottery,
  [Lotto.CENTRAL_QUANG_NGAI]: SouthernLottery,
  [Lotto.CENTRAL_DAK_NONG]: SouthernLottery,
  [Lotto.CENTRAL_KOM_TUM]: SouthernLottery,

  [Lotto.NORTHERN]: NorthernLottery,
};

export default createReducer<LottoState>(initialState, (builder) =>
  builder
    .addCase(reset, (state, { payload: { play } }) => {
      return {
        ...state,
        play,
        independentType: initialState[play].types[0],
        multiples: 1,
        betCount: 0,
        betAmount: 0,
      };
    })
    .addCase(
      selectionNumbers,
      (state, { payload: { type, method, numbers } }) => {
        let betCount = 0;
        if (numbers.length > 0) {
          if (method === Method.CHOOSE) {
            betCount =
              numbers.length === type.length
                ? numbers.map((n) => n.length).reduce((a, b) => a * b)
                : 0;
          } else {
            betCount = numbers.length;
          }
        }

        const betAmount = betCount * (state.multiples * type.amount);

        console.log({ type, method, numbers, betCount, betAmount });

        return {
          ...state,
          independentType: type,
          method,
          typedNumbers: numbers,
          betCount,
          betAmount,
        };
      }
    )
    .addCase(typeInput, (state, { payload: { type, typedValue } }) => {
      return {
        ...state,
        independentType: type,
        typedNumbers: [typedValue.split(",")],
      };
    })
    .addCase(setIssue, (state, { payload: { lotto, issue } }) => {
      return {
        ...initialState,
        lotto,
        issue,
      };
    })
    .addCase(setLotto, (state, { payload: { lotto, issue } }) => {
      const type = initialState[lotto][Play.ALL_RESULT].types[0];

      return {
        ...initialState,
        lotto,
        issue,
        play: Play.ALL_RESULT,
        independentType: type,
        typedNumbers: type.selections,
      };
    })
    .addCase(setPlay, (state, { payload: { lotto, play } }) => {
      const type = lotto
        ? initialState[lotto][play].types[0]
        : initialState[play].types[0];

      return {
        ...initialState,
        issue: state.issue,
        play,
        independentType: type,
        typedNumbers: type.selections,
      };
    })
    .addCase(setType, (state, { payload: { type } }) => {
      state.independentType = type;
    })
    .addCase(setMultiples, (state, { payload: { multiples } }) => {
      state.betAmount = (state.betAmount / state.multiples) * multiples;
      state.multiples = multiples;
    })
    .addCase(clearSelections, (state) => {
      const length = state.independentType.length;
      
      return {
        ...state,
        typedNumbers: Array.from(new Array(length).keys()).map((m) => []),
        betCount: 0,
        multiples: 1,
      };
    })
);
