import { useCallback, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Role } from "constants/index"
import { useGetCall } from "hooks"
import { AppDispatch, AppState } from "../index"
import {
  setLocale,
  setAccount,
  setRememberMe,
  clearAccount,
  setBalance,
  setRole,
  Account,
} from './actions'

export function useAccountState(): AppState['account'] {
  return useSelector<AppState, AppState['account']>((state) => state.account);
}

export function useAccountActionHandlers(): {
  onLocale: (locale?: string) => void
  onRememberMe: (rememberMe?: { loginId: string }) => void
  onLogged: (account: Account) => void
  onLogout: () => void
  onChangeBalance: (balance: number) => void
  onChangeRole: (role: Role | null) => void
} {
  const dispatch = useDispatch<AppDispatch>()

  const onLocale = useCallback((locale?: string) => dispatch(setLocale(locale)), [dispatch])
  const onRememberMe = useCallback((rememberMe?: { loginId: string }) => dispatch(setRememberMe(rememberMe)), [dispatch])

  const onLogged = useCallback((account: Account) => {
    if (account.accessToken && account.accessToken.length > 0) {
      localStorage.setItem('accessToken', account.accessToken)
    }
    dispatch(setAccount(account))
  }, [dispatch])

  const onLogout = useCallback(() => {
    localStorage.removeItem('accessToken')
    dispatch(clearAccount())
  }, [dispatch])

  const onChangeBalance = useCallback((balance: number) => {
    dispatch(setBalance({ balance }))
  }, [dispatch])

  const onChangeRole = useCallback((role: Role | null) => {
    dispatch(setRole({ role }))
  }, [dispatch])

  return {
    onLocale,
    onRememberMe,
    onLogged,
    onLogout,
    onChangeBalance,
    onChangeRole,
  };
}

export function useBalance(): () => Promise<number> {
  const { account } = useAccountState()
  const { onChangeBalance } = useAccountActionHandlers();
  const getCall = useGetCall(account ?? undefined)

  return useMemo(() => {
    return async function onBalance(): Promise<number> {
      return getCall('/account/balance').then(({ balance }) => {
        onChangeBalance(balance)
        
        return balance
      })
    }
  }, [getCall, onChangeBalance])
}