import { Play, PlayType, Method, Option, TypeBet } from "../actions";

export interface VietnamLottery {
  readonly [Play.ALL_RESULT]: {
    types: TypeBet[];
  };

  readonly [Play.PARLAY_BET]: {
    types: TypeBet[];
  };

  readonly [Play.SCORE]: {
    types: TypeBet[];
  };

  readonly [Play.FIRST_LAST]: {
    types: TypeBet[];
  };

  readonly [Play.THREE_NUMBERS]: {
    types: TypeBet[];
  };

  readonly [Play.FOUR_NUMBERS]: {
    types: TypeBet[];
  };

  readonly [Play.RESULT_LOSS]: {
    types: TypeBet[];
  };
}

export const SouthernLottery: VietnamLottery = {
  [Play.ALL_RESULT]: {
    types: [
      {
        name: PlayType.ALL_RESULT_TWO_NUMBERS,
        descriptions: "ALL_RESULT_TWO_NUMBERS_DESCRIPTIONS",
        odds: 98,
        amount: 18,
        length: 2,
        selections: [[], []],
        methods: {
          [Method.CHOOSE]: {
            selections: [Option.ROUND_3, Option.ROUND_4],
          },
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
        },
      },
      {
        name: PlayType.ALL_RESULT_TWO_NUMBERS_1ST_PRIZES,
        descriptions: "ALL_RESULT_TWO_FIRST_NUMBERS_DESCRIPTIONS",
        odds: 5.445,
        amount: 1,
        length: 2,
        selections: [[], []],
        methods: {
          [Method.CHOOSE]: {
            selections: [Option.ROUND_3, Option.ROUND_4],
          },
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
        },
      },
      {
        name: PlayType.ALL_RESULT_THREE_NUMBERS,
        descriptions: "ALL_RESULT_THREE_NUMBERS_DESCRIPTIONS",
        odds: 960,
        amount: 17,
        length: 3,
        selections: [[], [], []],
        methods: {
          [Method.CHOOSE]: {
            selections: [Option.ROUND_2, Option.ROUND_3, Option.ROUND_4],
          },
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
        },
      },
      {
        name: PlayType.ALL_RESULT_FOUR_NUMBERS,
        descriptions: "ALL_RESULT_FOUR_NUMBERS_DESCRIPTIONS",
        odds: 8880,
        amount: 16,
        length: 4,
        selections: [[], [], [], []],
        methods: {
          [Method.CHOOSE]: {
            selections: [
              Option.ROUND_1,
              Option.ROUND_2,
              Option.ROUND_3,
              Option.ROUND_4,
            ],
          },
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
          [Method.FAST_CHOOSE]: undefined,
        },
      },
    ],
  },

  [Play.PARLAY_BET]: {
    types: [
      {
        name: PlayType.PARLAY_BET_DOUBLE,
        descriptions: "PARLAY_BET_DOUBLE_DESCRIPTIONS",
        odds: 28,
        amount: 1,
        length: 1,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: undefined,
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_1,
              Option.RANDOM_2,
              Option.RANDOM_3,
              Option.RANDOM_5,
              Option.RANDOM_10,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [Option.RANDOM_1],
          },
        },
      },
      {
        name: PlayType.PARLAY_BET_TREBLE,
        descriptions: "PARLAY_BET_TREBLE_DESCRIPTIONS",
        odds: 150,
        amount: 1,
        length: 1,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: undefined,
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_1,
              Option.RANDOM_2,
              Option.RANDOM_3,
              Option.RANDOM_5,
              Option.RANDOM_10,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [Option.RANDOM_1],
          },
        },
      },
      {
        name: PlayType.PARLAY_BET_FOLD4,
        descriptions: "PARLAY_BET_FOLD4_DESCRIPTIONS",
        odds: 750,
        amount: 1,
        length: 1,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: undefined,
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_1,
              Option.RANDOM_2,
              Option.RANDOM_3,
              Option.RANDOM_5,
              Option.RANDOM_10,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [Option.RANDOM_1],
          },
        },
      },
    ],
  },

  [Play.SCORE]: {
    types: [
      {
        name: PlayType.SCORE_SPECIAL_PRIZE,
        descriptions: "SCORE_SPECIAL_PRIZE_DESCRIPTIONS",
        odds: 98,
        amount: 1,
        length: 2,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: {
            selections: [Option.ROUND_3, Option.ROUND_4],
          },
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
        },
      },
      {
        name: PlayType.SCORE_SPECIAL_HEADLINE,
        descriptions: "SCORE_SPECIAL_HEADLINE_DESCRIPTIONS",
        odds: 99,
        amount: 1,
        length: 2,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: {
            selections: [Option.ROUND_3, Option.ROUND_4],
          },
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
        },
      },
      {
        name: PlayType.SCORE_EIGHTH_PRIZE,
        descriptions: "SCORE_EIGHTH_PRIZE_DESCRIPTIONS",
        odds: 98,
        amount: 1,
        length: 2,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: {
            selections: [Option.ROUND_3, Option.ROUND_4],
          },
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
        },
      },
      {
        name: PlayType.SCORE_SPECIAL_TOPICS,
        descriptions: "SCORE_SPECIAL_TOPICS_DESCRIPTIONS",
        odds: 98,
        amount: 1,
        length: 2,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: {
            selections: [Option.ROUND_3, Option.ROUND_4],
          },
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
        },
      },
      {
        name: PlayType.SCORE_EIGHTH_SPECIAL_PRIZE,
        descriptions: "SCORE_EIGHTH_SPECIAL_PRIZE_DESCRIPTIONS",
        odds: 98,
        amount: 2,
        length: 2,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: {
            selections: [Option.ROUND_3, Option.ROUND_4],
          },
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
        },
      },
    ],
  },

  [Play.FIRST_LAST]: {
    types: [
      {
        name: PlayType.FIRST_LAST_FIRST,
        descriptions: "FIRST_LAST_FIRST_DESCRIPTIONS",
        odds: 98,
        amount: 10,
        length: 1,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: {
            selections: [Option.ROUND_3],
          },
          [Method.TYPE]: undefined,
          [Method.FAST_CHOOSE]: undefined,
        },
      },
      {
        name: PlayType.FIRST_LAST_LAST,
        descriptions: "FIRST_LAST_LAST_DESCRIPTIONS",
        odds: 98,
        amount: 10,
        length: 1,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: {
            selections: [Option.ROUND_4],
          },
          [Method.TYPE]: undefined,
          [Method.FAST_CHOOSE]: undefined,
        },
      },
    ],
  },

  [Play.THREE_NUMBERS]: {
    types: [
      {
        name: PlayType.THREE_NUMBERS_FIRST_PRIZE,
        descriptions: "THREE_NUMBERS_FIRST_PRIZE_DESCRIPTIONS",
        odds: 960,
        amount: 1,
        length: 3,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: {
            selections: [Option.ROUND_2, Option.ROUND_3, Option.ROUND_4],
          },
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
        },
      },
      {
        name: PlayType.THREE_NUMBERS_EIGHTH_PRIZE,
        descriptions: "THREE_NUMBERS_EIGHTH_PRIZE_DESCRIPTIONS",
        odds: 960,
        amount: 1,
        length: 3,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: {
            selections: [Option.ROUND_2, Option.ROUND_3, Option.ROUND_4],
          },
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
        },
      },
      {
        name: PlayType.THREE_NUMBERS_SPECIAL_PRIZE,
        descriptions: "THREE_NUMBERS_SPECIAL_PRIZE_DESCRIPTIONS",
        odds: 960,
        amount: 1,
        length: 3,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: {
            selections: [Option.ROUND_2, Option.ROUND_3, Option.ROUND_4],
          },
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
        },
      },
      {
        name: PlayType.THREE_NUMBERS_EIGHTH_SPECIAL_PRIZE,
        descriptions: "THREE_NUMBERS_EIGHTH_SPECIAL_PRIZE_DESCRIPTIONS",
        odds: 960,
        amount: 2,
        length: 3,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: {
            selections: [Option.ROUND_2, Option.ROUND_3, Option.ROUND_4],
          },
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
        },
      },
    ],
  },

  [Play.FOUR_NUMBERS]: {
    types: [
      {
        name: PlayType.FOUR_NUMBERS_SPECIAL_PRIZE,
        descriptions: "FOUR_NUMBERS_SPECIAL_PRIZE_DESCRIPTIONS",
        odds: 8880,
        amount: 1,
        length: 4,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: {
            selections: [
              Option.ROUND_1,
              Option.ROUND_2,
              Option.ROUND_3,
              Option.ROUND_4,
            ],
          },
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
          [Method.FAST_CHOOSE]: undefined,
        },
      },
    ],
  },

  [Play.RESULT_LOSS]: {
    types: [
      {
        name: PlayType.RESULT_LOSS_4,
        descriptions: "RESULT_LOSS_4_DESCRIPTIONS",
        odds: 1.8,
        amount: 1,
        length: 1,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: undefined,
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_1,
              Option.RANDOM_2,
              Option.RANDOM_3,
              Option.RANDOM_5,
              Option.RANDOM_10,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [Option.RANDOM_1],
          },
        },
      },
      {
        name: PlayType.RESULT_LOSS_8,
        descriptions: "RESULT_LOSS_8_DESCRIPTIONS",
        odds: 3.3,
        amount: 1,
        length: 1,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: undefined,
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_1,
              Option.RANDOM_2,
              Option.RANDOM_3,
              Option.RANDOM_5,
              Option.RANDOM_10,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [Option.RANDOM_1],
          },
        },
      },
      {
        name: PlayType.RESULT_LOSS_10,
        descriptions: "RESULT_LOSS_10_DESCRIPTIONS",
        odds: 4.3,
        amount: 1,
        length: 1,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: undefined,
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_1,
              Option.RANDOM_2,
              Option.RANDOM_3,
              Option.RANDOM_5,
              Option.RANDOM_10,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [Option.RANDOM_1],
          },
        },
      },
    ],
  },
};

export const NorthernLottery: VietnamLottery = {
  [Play.ALL_RESULT]: {
    types: [
      {
        name: PlayType.ALL_RESULT_TWO_NUMBERS,
        descriptions: "ALL_RESULT_TWO_NUMBERS_DESCRIPTIONS",
        odds: 98.5,
        amount: 27,
        length: 2,
        selections: [[], []],
        methods: {
          [Method.CHOOSE]: {
            selections: [Option.ROUND_3, Option.ROUND_4],
          },
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
        },
      },
      {
        name: PlayType.ALL_RESULT_TWO_FIRST_NUMBERS,
        descriptions: "ALL_RESULT_TWO_FIRST_NUMBERS_DESCRIPTIONS",
        odds: 98.5,
        amount: 23,
        length: 2,
        selections: [[], []],
        methods: {
          [Method.CHOOSE]: {
            selections: [Option.ROUND_3, Option.ROUND_4],
          },
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
        },
      },
      {
        name: PlayType.ALL_RESULT_TWO_NUMBERS_1ST_PRIZES,
        descriptions: "ALL_RESULT_TWO_FIRST_NUMBERS_DESCRIPTIONS",
        odds: 3.649,
        amount: 1,
        length: 2,
        selections: [[], []],
        methods: {
          [Method.CHOOSE]: {
            selections: [Option.ROUND_3, Option.ROUND_4],
          },
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
        },
      },
      {
        name: PlayType.ALL_RESULT_THREE_NUMBERS,
        descriptions: "ALL_RESULT_THREE_NUMBERS_DESCRIPTIONS",
        odds: 960,
        amount: 23,
        length: 3,
        selections: [[], [], []],
        methods: {
          [Method.CHOOSE]: {
            selections: [Option.ROUND_2, Option.ROUND_3, Option.ROUND_4],
          },
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
        },
      },
      {
        name: PlayType.ALL_RESULT_FOUR_NUMBERS,
        descriptions: "ALL_RESULT_FOUR_NUMBERS_DESCRIPTIONS",
        odds: 8880,
        amount: 20,
        length: 4,
        selections: [[], [], [], []],
        methods: {
          [Method.CHOOSE]: {
            selections: [
              Option.ROUND_1,
              Option.ROUND_2,
              Option.ROUND_3,
              Option.ROUND_4,
            ],
          },
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
          [Method.FAST_CHOOSE]: undefined,
        },
      },
    ],
  },

  [Play.PARLAY_BET]: {
    types: [
      {
        name: PlayType.PARLAY_BET_DOUBLE,
        descriptions: "PARLAY_BET_DOUBLE_DESCRIPTIONS",
        odds: 16,
        amount: 1,
        length: 1,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: undefined,
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_1,
              Option.RANDOM_2,
              Option.RANDOM_3,
              Option.RANDOM_5,
              Option.RANDOM_10,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [Option.RANDOM_1],
          },
        },
      },
      {
        name: PlayType.PARLAY_BET_TREBLE,
        descriptions: "PARLAY_BET_TREBLE_DESCRIPTIONS",
        odds: 65,
        amount: 1,
        length: 1,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: undefined,
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_1,
              Option.RANDOM_2,
              Option.RANDOM_3,
              Option.RANDOM_5,
              Option.RANDOM_10,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [Option.RANDOM_1],
          },
        },
      },
      {
        name: PlayType.PARLAY_BET_FOLD4,
        descriptions: "PARLAY_BET_FOLD4_DESCRIPTIONS",
        odds: 180,
        amount: 1,
        length: 1,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: undefined,
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_1,
              Option.RANDOM_2,
              Option.RANDOM_3,
              Option.RANDOM_5,
              Option.RANDOM_10,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [Option.RANDOM_1],
          },
        },
      },
    ],
  },

  [Play.SCORE]: {
    types: [
      {
        name: PlayType.SCORE_SPECIAL_PRIZE,
        descriptions: "SCORE_SPECIAL_PRIZE_DESCRIPTIONS",
        odds: 98,
        amount: 1,
        length: 2,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: {
            selections: [Option.ROUND_3, Option.ROUND_4],
          },
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
        },
      },
      {
        name: PlayType.SCORE_SPECIAL_TOPICS,
        descriptions: "SCORE_SPECIAL_TOPICS_DESCRIPTIONS",
        odds: 99,
        amount: 1,
        length: 2,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: {
            selections: [Option.ROUND_3, Option.ROUND_4],
          },
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
        },
      },
      {
        name: PlayType.SCORE_SPECIAL_HEADLINE,
        descriptions: "SCORE_SPECIAL_HEADLINE_DESCRIPTIONS",
        odds: 99,
        amount: 1,
        length: 2,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: {
            selections: [Option.ROUND_3, Option.ROUND_4],
          },
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
        },
      },
      {
        name: PlayType.SCORE_SEVENTH_PRIZE,
        descriptions: "SCORE_SEVENTH_PRIZE_DESCRIPTIONS",
        odds: 98,
        amount: 4,
        length: 2,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: {
            selections: [Option.ROUND_3, Option.ROUND_4],
          },
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
        },
      },
      {
        name: PlayType.SCORE_FIRST_PRIZE,
        descriptions: "SCORE_FIRST_PRIZE_DESCRIPTIONS",
        odds: 98,
        amount: 1,
        length: 2,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: {
            selections: [Option.ROUND_3, Option.ROUND_4],
          },
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.EVEN,
              Option.ODD,
              Option.BIG,
              Option.SMALL,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
        },
      },
    ],
  },

  [Play.FIRST_LAST]: {
    types: [
      {
        name: PlayType.FIRST_LAST_FIRST,
        descriptions: "FIRST_LAST_FIRST_DESCRIPTIONS",
        odds: 98,
        amount: 10,
        length: 1,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: {
            selections: [Option.ROUND_3],
          },
          [Method.TYPE]: undefined,
          [Method.FAST_CHOOSE]: undefined,
        },
      },
      {
        name: PlayType.FIRST_LAST_LAST,
        descriptions: "FIRST_LAST_LAST_DESCRIPTIONS",
        odds: 98,
        amount: 10,
        length: 1,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: {
            selections: [Option.ROUND_4],
          },
          [Method.TYPE]: undefined,
          [Method.FAST_CHOOSE]: undefined,
        },
      },
    ],
  },

  [Play.THREE_NUMBERS]: {
    types: [
      {
        name: PlayType.THREE_NUMBERS_TREBLE,
        descriptions: "THREE_NUMBERS_TREBLE_DESCRIPTIONS",
        odds: 960,
        amount: 3,
        length: 3,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: {
            selections: [Option.ROUND_2, Option.ROUND_3, Option.ROUND_4],
          },
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
        },
      },
      {
        name: PlayType.THREE_NUMBERS_FIRST_LAST,
        descriptions: "THREE_NUMBERS_FIRST_LAST_DESCRIPTIONS",
        odds: 960,
        amount: 4,
        length: 3,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: {
            selections: [Option.ROUND_2, Option.ROUND_3, Option.ROUND_4],
          },
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
        },
      },
      {
        name: PlayType.THREE_NUMBERS_FIRST_PRIZE,
        descriptions: "THREE_NUMBERS_FIRST_PRIZE_DESCRIPTIONS",
        odds: 960,
        amount: 1,
        length: 3,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: {
            selections: [Option.ROUND_2, Option.ROUND_3, Option.ROUND_4],
          },
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
        },
      },
      {
        name: PlayType.THREE_NUMBERS_SPECIAL_PRIZE,
        descriptions: "THREE_NUMBERS_SPECIAL_PRIZE_DESCRIPTIONS",
        odds: 960,
        amount: 1,
        length: 3,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: {
            selections: [Option.ROUND_2, Option.ROUND_3, Option.ROUND_4],
          },
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
        },
      },
    ],
  },

  [Play.FOUR_NUMBERS]: {
    types: [
      {
        name: PlayType.FOUR_NUMBERS_SPECIAL_PRIZE,
        descriptions: "FOUR_NUMBERS_SPECIAL_PRIZE_DESCRIPTIONS",
        odds: 8880,
        amount: 1,
        length: 4,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: {
            selections: [
              Option.ROUND_1,
              Option.ROUND_2,
              Option.ROUND_3,
              Option.ROUND_4,
            ],
          },
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_10,
              Option.RANDOM_20,
              Option.RANDOM_30,
              Option.RANDOM_40,
              Option.RANDOM_50,
              Option.DOUBLE_EQUALS,
              Option.TOP_1,
              Option.TOP_2,
              Option.TOP_5,
              Option.TOP_10,
              Option.TOP_20,
            ],
          },
          [Method.FAST_CHOOSE]: undefined,
        },
      },
    ],
  },

  [Play.RESULT_LOSS]: {
    types: [
      {
        name: PlayType.RESULT_LOSS_4,
        descriptions: "RESULT_LOSS_4_DESCRIPTIONS",
        odds: 2.3,
        amount: 1,
        length: 1,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: undefined,
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_1,
              Option.RANDOM_2,
              Option.RANDOM_3,
              Option.RANDOM_5,
              Option.RANDOM_10,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [Option.RANDOM_1],
          },
        },
      },
      {
        name: PlayType.RESULT_LOSS_8,
        descriptions: "RESULT_LOSS_8_DESCRIPTIONS",
        odds: 8,
        amount: 1,
        length: 1,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: undefined,
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_1,
              Option.RANDOM_2,
              Option.RANDOM_3,
              Option.RANDOM_5,
              Option.RANDOM_10,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [Option.RANDOM_1],
          },
        },
      },
      {
        name: PlayType.RESULT_LOSS_10,
        descriptions: "RESULT_LOSS_10_DESCRIPTIONS",
        odds: 12,
        amount: 1,
        length: 1,
        selections: [[]],
        methods: {
          [Method.CHOOSE]: undefined,
          [Method.TYPE]: {
            selections: [
              Option.RANDOM_1,
              Option.RANDOM_2,
              Option.RANDOM_3,
              Option.RANDOM_5,
              Option.RANDOM_10,
            ],
          },
          [Method.FAST_CHOOSE]: {
            selections: [Option.RANDOM_1],
          },
        },
      },
    ],
  },
};
