import { createAction } from '@reduxjs/toolkit'
import { Lotto, Play, PlayType, Method } from '../lotto/actions'

export interface TicketReceipt {
  readonly lotto: Lotto
  readonly issue: string
  readonly play: Play
  readonly typeBet: PlayType
  readonly method: Method
  readonly odds: number
  readonly selections: String[][]
  readonly totalBet: number
  readonly multiples: number
  readonly amount: number
  readonly amountWin: number
  readonly id: string
  readonly currency: string
  readonly timestamp: number
  readonly owner: string
  readonly from: string
  readonly status: string
  readonly logs: { [key: string]: any}
}

export const typeInput = createAction<{ typedValue: string }>('account/typeInput')
export const addBet = createAction<{ accountId: string; txId: string; receipt: TicketReceipt }>('bet/addBet')
export const clearAllBets = createAction('bet/clearAllBets')
