import { createAction } from "@reduxjs/toolkit";

export enum Lotto {
  NORTHERN = "NORTHERN",

  SOUTHERN_HO_CHI_MINH = "SOUTHERN_HO_CHI_MINH",
  SOUTHERN_DONG_THAP = "SOUTHERN_DONG_THAP",
  SOUTHERN_CA_MAU = "SOUTHERN_CA_MAU",
  SOUTHERN_BEN_TRE = "SOUTHERN_BEN_TRE",
  SOUTHERN_VUNG_TAU = "SOUTHERN_VUNG_TAU",
  SOUTHERN_BAC_LIEU = "SOUTHERN_BAC_LIEU",
  SOUTHERN_DONG_NAI = "SOUTHERN_DONG_NAI",
  SOUTHERN_CAN_THO = "SOUTHERN_CAN_THO",
  SOUTHERN_SOC_TRANG = "SOUTHERN_SOC_TRANG",
  SOUTHERN_TAY_NINH = "SOUTHERN_TAY_NINH",
  SOUTHERN_AN_GIANG = "SOUTHERN_AN_GIANG",
  SOUTHERN_BINH_THUAN = "SOUTHERN_BINH_THUAN",
  SOUTHERN_VINH_LONG = "SOUTHERN_VINH_LONG",
  SOUTHERN_BINH_DUONG = "SOUTHERN_BINH_DUONG",
  SOUTHERN_TRA_VINH = "SOUTHERN_TRA_VINH",
  SOUTHERN_LONG_AN = "SOUTHERN_LONG_AN",
  SOUTHERN_BINH_PHUOC = "SOUTHERN_BINH_PHUOC",
  SOUTHERN_HAU_GIANG = "SOUTHERN_HAU_GIANG",
  SOUTHERN_TIEN_GIANG = "SOUTHERN_TIEN_GIANG",
  SOUTHERN_KIEN_GIANG = "SOUTHERN_KIEN_GIANG",
  SOUTHERN_DA_LAT = "SOUTHERN_DA_LAT",

  CENTRAL_HUE = "CENTRAL_HUE",
  CENTRAL_PHU_YEN = "CENTRAL_PHU_YEN",
  CENTRAL_DAK_LAK = "CENTRAL_DAK_LAK",
  CENTRAL_QUANG_NAM = "CENTRAL_QUANG_NAM",
  CENTRAL_DA_NANG = "CENTRAL_DA_NANG",
  CENTRAL_KHANH_HOA = "CENTRAL_KHANH_HOA",
  CENTRAL_BINH_DINH = "CENTRAL_BINH_DINH",
  CENTRAL_QUANG_TRI = "CENTRAL_QUANG_TRI",
  CENTRAL_QUANG_BINH = "CENTRAL_QUANG_BINH",
  CENTRAL_GIA_LAI = "CENTRAL_GIA_LAI",
  CENTRAL_NINH_THUAN = "CENTRAL_NINH_THUAN",
  CENTRAL_QUANG_NGAI = "CENTRAL_QUANG_NGAI",
  CENTRAL_DAK_NONG = "CENTRAL_DAK_NONG",
  CENTRAL_KOM_TUM = "CENTRAL_KOM_TUM",
}

export enum Play {
  ALL_RESULT = "ALL_RESULT",
  PARLAY_BET = "PARLAY_BET",
  SCORE = "SCORE",
  FIRST_LAST = "FIRST_LAST",
  THREE_NUMBERS = "THREE_NUMBERS",
  FOUR_NUMBERS = "FOUR_NUMBERS",
  RESULT_LOSS = "RESULT_LOSS",
}

export enum PlayType {
  ALL_RESULT_TWO_NUMBERS = "ALL_RESULT_TWO_NUMBERS",
  ALL_RESULT_TWO_FIRST_NUMBERS = "ALL_RESULT_TWO_FIRST_NUMBERS",
  ALL_RESULT_TWO_NUMBERS_1ST_PRIZES = "ALL_RESULT_TWO_NUMBERS_1ST_PRIZES",
  ALL_RESULT_THREE_NUMBERS = "ALL_RESULT_THREE_NUMBERS",
  ALL_RESULT_FOUR_NUMBERS = "ALL_RESULT_FOUR_NUMBERS",
  PARLAY_BET_DOUBLE = "PARLAY_BET_DOUBLE",
  PARLAY_BET_TREBLE = "PARLAY_BET_TREBLE",
  PARLAY_BET_FOLD4 = "PARLAY_BET_FOLD4",
  SCORE_SPECIAL_PRIZE = "SCORE_SPECIAL_PRIZE",
  SCORE_SPECIAL_TOPICS = "SCORE_SPECIAL_TOPICS",
  SCORE_SPECIAL_HEADLINE = "SCORE_SPECIAL_HEADLINE",
  SCORE_SEVENTH_PRIZE = "SCORE_SEVENTH_PRIZE",
  SCORE_EIGHTH_PRIZE = "SCORE_EIGHTH_PRIZE",
  SCORE_EIGHTH_SPECIAL_PRIZE = "SCORE_EIGHTH_SPECIAL_PRIZE",
  SCORE_FIRST_PRIZE = "SCORE_FIRST_PRIZE",
  FIRST_LAST_FIRST = "FIRST_LAST_FIRST",
  FIRST_LAST_LAST = "FIRST_LAST_LAST",
  THREE_NUMBERS_TREBLE = "THREE_NUMBERS_TREBLE",
  THREE_NUMBERS_FIRST_LAST = "THREE_NUMBERS_FIRST_LAST",
  THREE_NUMBERS_FIRST_PRIZE = "THREE_NUMBERS_FIRST_PRIZE",
  THREE_NUMBERS_SPECIAL_PRIZE = "THREE_NUMBERS_SPECIAL_PRIZE",
  THREE_NUMBERS_EIGHTH_PRIZE = "THREE_NUMBERS_EIGHTH_PRIZE",
  THREE_NUMBERS_EIGHTH_SPECIAL_PRIZE = "THREE_NUMBERS_EIGHTH_SPECIAL_PRIZE",
  FOUR_NUMBERS_SPECIAL_PRIZE = "FOUR_NUMBERS_SPECIAL_PRIZE",
  RESULT_LOSS_4 = "RESULT_LOSS_4",
  RESULT_LOSS_8 = "RESULT_LOSS_8",
  RESULT_LOSS_10 = "RESULT_LOSS_10",
}

export enum Method {
  CHOOSE = "CHOOSE",
  TYPE = "TYPE",
  FAST_CHOOSE = "FAST_CHOOSE",
}

export enum Option {
  RANDOM_1 = "OPTION_RANDOM_1",
  RANDOM_2 = "OPTION_RANDOM_2",
  RANDOM_3 = "OPTION_RANDOM_3",
  RANDOM_5 = "OPTION_RANDOM_5",
  RANDOM_10 = "OPTION_RANDOM_10",
  RANDOM_20 = "OPTION_RANDOM_20",
  RANDOM_30 = "OPTION_RANDOM_30",
  RANDOM_40 = "OPTION_RANDOM_40",
  RANDOM_50 = "OPTION_RANDOM_50",
  DOUBLE_EQUALS = "OPTION_DOUBLE_EQUALS",
  EVEN = "OPTION_EVEN",
  ODD = "OPTION_ODD",
  BIG = "OPTION_BIG",
  SMALL = "OPTION_SMALL",
  TOP_1 = "OPTION_TOP_1",
  TOP_2 = "OPTION_TOP_2",
  TOP_5 = "OPTION_TOP_5",
  TOP_10 = "OPTION_TOP_10",
  TOP_20 = "OPTION_TOP_20",
  ROUND_1 = "OPTION_ROUND_1",
  ROUND_2 = "OPTION_ROUND_2",
  ROUND_3 = "OPTION_ROUND_3",
  ROUND_4 = "OPTION_ROUND_4",
}

export interface Selection {
  label: string;
  numbers: string[];
}

export interface TypeBet {
  readonly name: PlayType;
  readonly descriptions: string;
  readonly odds: number;
  readonly amount: number;
  readonly length: number;
  readonly selections: String[][];
  readonly methods: {
    [method in Method]: { readonly selections: Option[] } | undefined;
  };
}

export interface Ticket {
  readonly lotto: Lotto;
  readonly issue: string;
  readonly play: Play;
  readonly typeBet: PlayType;
  readonly method: Method;
  readonly odds: number;
  readonly selections: String[][];
  readonly totalBet: number;
  readonly multiples: number;
  readonly amount: number;
  readonly amountWin: number;
  readonly timestamp?: number;
  readonly status?: string;
}

export const AllLottos = [
  Lotto.NORTHERN,

  Lotto.SOUTHERN_HO_CHI_MINH,
  Lotto.SOUTHERN_DONG_THAP,
  Lotto.SOUTHERN_CA_MAU,
  Lotto.SOUTHERN_BEN_TRE,
  Lotto.SOUTHERN_VUNG_TAU,
  Lotto.SOUTHERN_BAC_LIEU,
  Lotto.SOUTHERN_DONG_NAI,
  Lotto.SOUTHERN_CAN_THO,
  Lotto.SOUTHERN_SOC_TRANG,
  Lotto.SOUTHERN_TAY_NINH,
  Lotto.SOUTHERN_AN_GIANG,
  Lotto.SOUTHERN_BINH_THUAN,
  Lotto.SOUTHERN_VINH_LONG,
  Lotto.SOUTHERN_BINH_DUONG,
  Lotto.SOUTHERN_TRA_VINH,
  Lotto.SOUTHERN_LONG_AN,
  Lotto.SOUTHERN_BINH_PHUOC,
  Lotto.SOUTHERN_HAU_GIANG,
  Lotto.SOUTHERN_TIEN_GIANG,
  Lotto.SOUTHERN_KIEN_GIANG,
  Lotto.SOUTHERN_DA_LAT,

  Lotto.CENTRAL_HUE,
  Lotto.CENTRAL_PHU_YEN,
  Lotto.CENTRAL_DAK_LAK,
  Lotto.CENTRAL_QUANG_NAM,
  Lotto.CENTRAL_DA_NANG,
  Lotto.CENTRAL_KHANH_HOA,
  Lotto.CENTRAL_BINH_DINH,
  Lotto.CENTRAL_QUANG_TRI,
  Lotto.CENTRAL_QUANG_BINH,
  Lotto.CENTRAL_GIA_LAI,
  Lotto.CENTRAL_NINH_THUAN,
  Lotto.CENTRAL_QUANG_NGAI,
  Lotto.CENTRAL_DAK_NONG,
  Lotto.CENTRAL_KOM_TUM,
];

export const reset = createAction<{ play: Play }>("lotto/reset");
export const selectionNumbers = createAction<{
  type: TypeBet;
  method: Method;
  numbers: String[][];
}>("lotto/selectionNumbers");
export const typeInput = createAction<{ type: TypeBet; typedValue: String }>(
  "lotto/typeInput"
);
export const setIssue = createAction<{ lotto: Lotto; issue: string }>(
  "lotto/setIssue"
);
export const setLotto = createAction<{ lotto: Lotto; issue: string }>(
  "lotto/setLotto"
);
export const setPlay = createAction<{ lotto?: Lotto; play: Play }>(
  "lotto/setPlay"
);
export const setType = createAction<{ type: TypeBet }>("lotto/setType");
export const setMultiples = createAction<{ multiples: number }>(
  "lotto/setMultiples"
);
export const clearSelections = createAction("lotto/clearSelections");
