import { createReducer } from "@reduxjs/toolkit"
import { Role } from "constants/index"
import { 
  setLocale,
  setRememberMe, 
  setAccount, 
  clearAccount, 
  setBalance, 
  updateToken,
  setRole,
  Account,
} from "./actions"

export interface AccountState {
  readonly locale: string | undefined
  readonly rememberMe: { loginId: string } | undefined
  readonly account: Account | undefined

  readonly role: Role | null
}

const initialState: AccountState = {
  locale: "en",
  rememberMe: undefined,
  account: undefined,
  role: null,
}

export default createReducer<AccountState>(initialState, (builder) =>
  builder
    .addCase(
      setLocale, (state, { payload }) => {
        return { ...state, locale: payload }
      }
    )
    .addCase(
      setRememberMe, (state, { payload }) => {
        return { ...state, rememberMe: payload }
      }
    )
    .addCase(setAccount, (state, { payload }) => {
        return { ...state, account: payload }
    })
    .addCase(clearAccount, (state) => {
      return { ...state, account: undefined, role: null }
    })
    .addCase(setBalance, (state, { payload: { balance } }) => {
      if (state.account) {
        return { ...state, account: { ...state.account, balance } }
      }

      return { ...state }
    })
    .addCase(updateToken, (state, { payload: { token } }) => {
      if (state.account) {
        // return { ...state, account: { ...state.account, accessToken } }
        // state.account = { ...state.account, accessToken: token }
      }
    })
    .addCase(setRole, (state, { payload: { role } }) => {
      state.role = role
    })
);