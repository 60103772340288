import { useEffect, useState, useCallback } from 'react'
import sha1 from 'sha1'
import axios from 'axios'
import { useMutation, useQuery } from '@apollo/client'
import { useAxios } from './useAxios'
import { Account } from '../state/account/actions'
import { Lotto, Ticket } from '../state/lotto/actions'
import { TicketReceipt } from '../state/bet/actions'
import { useAccountActionHandlers } from '../state/account/hooks'
import { LOGOUT, GET_ACCOUNT } from 'constants/graphql'

const api = axios.create({ 
  baseURL: `${process.env.REACT_APP_BACKEND_URL}`, 
  timeout: 10 * 1000,
  headers: {
    'Content-Type': 'application/json',
  }
})

function authorizationHeaders(account?: Account) {
  if (account && account.accessToken) {
    return {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${account.accessToken}`,
    }
  } else {
    return {
      'Content-Type': 'application/json',
    }
  }
}

export function useGetCall(account?: Account): (url:string, data?: any) => Promise<any> {
  return useCallback((url: string) => {
    return api.get(url, { headers: authorizationHeaders(account) }).then(({ data }) => data)
  }, [account])
}

export function usePostCall(account?: Account): (url: string, data?: any) => Promise<any> {
  return useCallback((url: string, data?: any) => {
    return api.post(url, data, { headers: authorizationHeaders(account) }).then(({ data }) => data)
  }, [account])
}

export function useLotto(): { 
  name?: Lotto
  date: string
  issue?: string
  week: string
  stopBettingAt: number
} {
  const [result, setResult] = useState<{
    name?: Lotto
    date: string
    issue?: string
    week: string
    stopBettingAt: number
   }>
   ({
    date: "",
    week: "",
    stopBettingAt: 0,
   });

  const axios = useAxios()

  useEffect(
    () => {
      axios.get(`/lotto`).then(reps => setResult(reps.data))
    }, 
    [axios]
  )

  return result;
}

export function useLottoWithName(name?: string | Lotto): { 
  name?: Lotto
  date: number
  issue: string
  week: string
  stopBettingAt: number
  status: string
  days: string[]
  bonusPool: { [key: string]: number } | undefined
} {
  const [result, setResult] = useState<{
    name?: Lotto
    date: number
    issue: string
    week: string
    stopBettingAt: number
    status: string
    days: string[]
    bonusPool: { [key: string]: number } | undefined
   }>({
     name: undefined,
     date: 0,
     issue: '',
     week: '',
     stopBettingAt: 0,
     status: '',
     days: [],
     bonusPool: undefined,
   });

  useEffect(
    () => {
      if (name) {
        api.get(`/lotto/${name.toLowerCase()}`).then(reps => setResult(reps.data))
      }
    }, 
    [name]
  )

  return result
}

export function useResult({ lotto, date }: {lotto?: Lotto | string, date?: string}): {
    issue: string
    stages: string[][]
    firstLast: string[][]
} {
  const [result, setResult] = useState<{
    issue: string
    stages: string[][]
    firstLast: string[][]
   }>
   ({
    issue: "",
    stages: [],
    firstLast: []
   });

  const axios = useAxios()
  
  useEffect(
    () => {
      if (date) {
        if (lotto) {
          axios.get(`/result/${lotto.toLowerCase()}/${date}`).then(reps => setResult(reps.data))
        } else {
          axios.get(`/result/${date}`).then(reps => setResult(reps.data))
        }
      }
    },
    [axios, lotto, date]
  )

  return result
}

export function useAccount(): Account | undefined {
  const { data, error } = useQuery(GET_ACCOUNT, { variables: { id: null }})
  const [result, setResult] = useState<Account | undefined>(undefined)

  const { onLogout, onLogged } = useAccountActionHandlers()

  useEffect(() => {
    console.log({ error })
    if (error && (error.message === "unauthorized" || error.message === "invalid token")) {
      onLogout()
    }
  }, [onLogout, error])

  useEffect(
    () => {
      if (data) {
        onLogged(data.account)
        setResult(data.account)
      }
    },
    [onLogged, data]
  )

  return result
}

export function useLogin(loginId?: string, password?: string): { 
  callback: null | (() => Promise<Account>); 
  error: string | null; 
} {
  const axios = useAxios()
  
  if (loginId && password) {
    console.log({ password: sha1(password) })
    return {
      callback: async function onLogin(): Promise<Account> { 
        return axios.post( `/login`, { 
          loginId, 
          password: sha1(password + "08993056").toUpperCase() 
        }).then(reps => reps.data)
      },
      error: null,
    }
  }

  return {
    callback: null,
    error: null,
  }
}

export function useLoginWithToken(token?: string): void {
  const { onLogged } = useAccountActionHandlers()

  useEffect(
    () => {
      if (token && token.length > 0) {
        api.post(`/login/${token}`).then(reps => {
          onLogged && onLogged(reps.data)
        })
      }
    },
    [onLogged, token]
  )
}

export function useBetting(tickets?: Ticket[]): (() => Promise<string>) | null {
  const axios = useAxios()
  
  if (tickets && tickets.length > 0) {
    return async function onBetting(): Promise<string>{ 
      return axios.post( '/betting', tickets).then(reps => reps.data.id)
    }
  }
  
  return null
}

export function useBetHistory(account?: Account)
  : (page: number, pageSize: number) => Promise<{ total: number; receipts: TicketReceipt[] }> {

  return useCallback(
    (page: number, pageSize: number) => {
      if (account) {
        return api.get(`/betting/history/${page}/${pageSize}`, { headers: authorizationHeaders(account) })
          .then(({ data }) => {
            return { total: data.length, receipts: data }
          })
      } else {
        return new Promise((resolve, reject) => resolve({ total: 0, receipts: [] }))
      }
    },
    [account]
  )
}

export function useBetHistory2(
  account?: Account,
  lotto?: Lotto | string, 
  date?: Date,
  pageNumber?: number, 
  pageSize?: number,
  status?: string,
): {
    content: TicketReceipt[]
    total: number
    pages: number
    pageNumber: number
    pageSize: number
} {
  const [result, setResult] = useState<{
    content: TicketReceipt[]
    total: number
    pages: number
    pageNumber: number
    pageSize: number
   }>
   ({
    content: [],
    total: 0,
    pages: 0,
    pageNumber: 0,
    pageSize: 0,
   });

  useEffect(
    () => {
      if (account && lotto && date) {
        const formattedDate = `${date.getFullYear()}`
          + `-${(date.getMonth() + 1).toString().padStart(2, '0')}` 
          + `-${date.getDate().toString().padStart(2, '0')}`

        const url = `/betting/history/${lotto.toLowerCase()}/${formattedDate}/${pageNumber ?? 0}/${pageSize ?? 20}`
          + (status ? `?status=${status}` : '')
          
        api.get(url, { headers: authorizationHeaders(account) })
          .then(reps => setResult(reps.data))
      }
    },
    [account, lotto, date, pageNumber, pageSize, status]
  )

  return result
}

export function useUpdateResult({ 
  account, 
  lotto, 
  date,
  stages, 
  needSettle,
}: { 
  account?: Account
  lotto?: Lotto | string
  date?: string
  stages?: string[][]
  needSettle?: boolean 
}): { callback: null | (() => Promise<{ issue: string, stages: string[][] }>) } {
  const postCall = usePostCall(account)
  
  if (lotto && date && stages) {
    return {
      callback: async function onSettle(): Promise<{ issue: string, stages: string[][] }> { 
        return postCall(`/result/${lotto.toLowerCase()}/${date}`, { 
          needSettle: needSettle ?? false, 
          stages
        }).then(reps => reps.data)
      }
    }
  }

  return {
    callback: null
  }
}

export function useAgent(account?: Account, lotto?: Lotto | string, date?: string): {
  ouid: number
  name: string
  accessKey: string
  status: string
  members: number
  totalBet: number
  totalAmount: number
}[] {
  const [result, setResult] = useState<{
    ouid: number
    name: string
    accessKey: string
    status: string
    members: number
    totalBet: number
    totalAmount: number
  }[]>([]);
  
  useEffect(
    () => {
      if (account && lotto && date) {
        api.get(`/agent/${lotto}/${date}`, { headers: authorizationHeaders(account) })
          .then(reps => setResult(reps.data))
      }
    },
    [account, lotto, date]
  )

  return result
}

export function useLottoTurnover(account?: Account, lotto?: Lotto | string, date?: string): {
  totalBet: number
  totalAmount: number
} {
  const [result, setResult] = useState<{
    totalBet: number
    totalAmount: number
  }>({
    totalBet: 0,
    totalAmount: 0,
  });
  
  useEffect(
    () => {
      if (account && lotto && date) {
        api.get(`/lotto/turnover/${lotto}/${date}`, { headers: authorizationHeaders(account) })
          .then(reps => setResult(reps.data))
      }
    },
    [account, lotto, date]
  )

  return result
}

export function useLogout() {
  const { onLogout } = useAccountActionHandlers()
  const [logout, { data, error }] = useMutation(LOGOUT, {
    onCompleted: () => {
      onLogout()
    }
  })
  
  return {
    logout,
    data,
    error
  }
}

