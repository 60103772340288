import { createReducer } from '@reduxjs/toolkit'
import {
  addBet,
  clearAllBets,
  TicketReceipt,
  typeInput,
} from './actions'

export interface BetState {
  readonly typedValue: string
  readonly bets: { [accountId: string]: { [txId: string]: TicketReceipt } }
}

export const initialState: BetState = {
  typedValue: '',
  bets: {}
}

export default createReducer(initialState, builder =>
  builder
    .addCase(addBet, (state, { payload: { accountId, txId, receipt } }) => {
      // if (bets[accountId]?.[txId]) {
      //   // throw Error('Attempted to add existing bet.')
      //   return
      // }
      const txs = state.bets[accountId] ?? {}
      txs[txId] = receipt
      state.bets[accountId] = txs
    })
    .addCase(clearAllBets, (state) => {
      if (!state) return
      state.bets = {}
    })
    .addCase(typeInput, (state, { payload: { typedValue } }) => {
      return {
        ...state,
        typedValue
      }
    })
)
