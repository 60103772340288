import { PlayType } from "../state/lotto/actions";

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

export function ago(time: number) {
    const v = Math.floor(new Date().getTime() / 1000) - time
    if (v < 60) {
        return `${v}秒前`
    }
    if (v < 3600) {
        return `${Math.floor(v / 60)}分钟前`
    }
    if (v < 3600 * 24) {
        return `${Math.floor(v / 3600)}小时前`
    }
    const days = Math.floor(v / (3600 * 24))
    if (days < 2) {
        return `昨天`
    }

    const d = new Date(time * 1000)
    const year = `${d.getFullYear()}`
    const month = `${d.getMonth() + 1}`.padStart(2, "0")
    const date = `${d.getDate()}`.padStart(2, "0")

    return `${year}-${month}-${date}`
}

export function today() {
  const d = new Date()
  return new Date(d.getFullYear(), d.getMonth(), d.getDate())
}

export function toDate(d: Date): Date {
  return new Date(d.getFullYear(), d.getMonth(), d.getDate())
}

export function formattedNumerical(v?: number, fractionDigits = 2) {
  if (!v || isNaN(v)) {
    return (0).toFixed(fractionDigits)
  }
  return v.toFixed(fractionDigits).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
}

export function formattedDate(d: Date) {
  const year = `${d.getFullYear()}`
  const month = `${d.getMonth() + 1}`.padStart(2, "0")
  const date = `${d.getDate()}`.padStart(2, "0")

  return `${year}-${month}-${date}`
}

export function formattedDateTime(d: Date) {
  const year = `${d.getFullYear()}`;
  const month = `${d.getMonth() + 1}`.padStart(2, "0")
  const date = `${d.getDate()}`.padStart(2, "0")
  const hours = `${d.getHours()}`.padStart(2, "0")
  const minutes = `${d.getMinutes()}`.padStart(2, "0")
  const seconds = `${d.getSeconds()}`.padStart(2, "0")

  return `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`
}

export function formattedSelection(numbers: String[][]) {
  return numbers.length === 1
    ? numbers[0].join(" | ")
    : numbers.map((n) => n.join()).join(" | ");
}

export function parsedNumber(string?: string): number | undefined {
  if (string) {
    const v = parseInt(string)
    if (!isNaN(v)) return v
  }
  return undefined
}

export function randomNumber() {
  return Math.floor(Math.random() * 10);
}

export function generateNumbers(start: number, end: number) {
  return Array.from(new Array(end + 1).keys()).slice(start);
}

export function generateRandomNumbers(numbers: String[], length: number) {
  let result = [];

  for (let i = 0; i < length; ) {
    const num = numbers[Math.floor(Math.random() * numbers.length)];
    if (result.indexOf(num) === -1) {
      result.push(num);
      i++;
    }
  }

  return result.sort();
}

export const NumberGenerator: { [type in PlayType]: () => string[] } = {
  [PlayType.ALL_RESULT_TWO_NUMBERS]: () =>
    generateNumbers(0, 99).map((n) => `${n}`.padStart(2, "0")),
  [PlayType.ALL_RESULT_TWO_FIRST_NUMBERS]: () =>
    generateNumbers(0, 99).map((n) => `${n}`.padStart(2, "0")),
  [PlayType.ALL_RESULT_TWO_NUMBERS_1ST_PRIZES]: () =>
    generateNumbers(0, 99).map((n) => `${n}`.padStart(2, "0")),
  [PlayType.ALL_RESULT_THREE_NUMBERS]: () =>
    generateNumbers(0, 999).map((n) => `${n}`.padStart(3, "0")),
  [PlayType.ALL_RESULT_FOUR_NUMBERS]: () =>
    generateNumbers(0, 9999).map((n) => `${n}`.padStart(4, "0")),
  [PlayType.PARLAY_BET_DOUBLE]: () =>
    generateNumbers(0, 99).map((n) => `${n}`.padStart(2, "0")),
  [PlayType.PARLAY_BET_TREBLE]: () =>
    generateNumbers(0, 99).map((n) => `${n}`.padStart(2, "0")),
  [PlayType.PARLAY_BET_FOLD4]: () =>
    generateNumbers(0, 99).map((n) => `${n}`.padStart(2, "0")),
  [PlayType.SCORE_SPECIAL_PRIZE]: () =>
    generateNumbers(0, 99).map((n) => `${n}`.padStart(2, "0")),
  [PlayType.SCORE_SPECIAL_TOPICS]: () =>
    generateNumbers(0, 99).map((n) => `${n}`.padStart(2, "0")),
  [PlayType.SCORE_SPECIAL_HEADLINE]: () =>
    generateNumbers(0, 99).map((n) => `${n}`.padStart(2, "0")),
  [PlayType.SCORE_SEVENTH_PRIZE]: () =>
    generateNumbers(0, 99).map((n) => `${n}`.padStart(2, "0")),
  [PlayType.SCORE_EIGHTH_PRIZE]: () =>
    generateNumbers(0, 99).map((n) => `${n}`.padStart(2, "0")),
  [PlayType.SCORE_EIGHTH_SPECIAL_PRIZE]: () =>
    generateNumbers(0, 99).map((n) => `${n}`.padStart(2, "0")),
  [PlayType.SCORE_FIRST_PRIZE]: () =>
    generateNumbers(0, 99).map((n) => `${n}`.padStart(2, "0")),
  [PlayType.FIRST_LAST_FIRST]: () => [],
  [PlayType.FIRST_LAST_LAST]: () => [],
  [PlayType.THREE_NUMBERS_TREBLE]: () =>
    generateNumbers(0, 999).map((n) => `${n}`.padStart(3, "0")),
  [PlayType.THREE_NUMBERS_FIRST_LAST]: () =>
    generateNumbers(0, 999).map((n) => `${n}`.padStart(3, "0")),
  [PlayType.THREE_NUMBERS_FIRST_PRIZE]: () =>
    generateNumbers(0, 999).map((n) => `${n}`.padStart(3, "0")),
  [PlayType.THREE_NUMBERS_SPECIAL_PRIZE]: () =>
    generateNumbers(0, 999).map((n) => `${n}`.padStart(3, "0")),
  [PlayType.THREE_NUMBERS_EIGHTH_PRIZE]: () =>
    generateNumbers(0, 999).map((n) => `${n}`.padStart(3, "0")),
  [PlayType.THREE_NUMBERS_EIGHTH_SPECIAL_PRIZE]: () =>
    generateNumbers(0, 999).map((n) => `${n}`.padStart(3, "0")),
  [PlayType.FOUR_NUMBERS_SPECIAL_PRIZE]: () =>
    generateNumbers(0, 9999).map((n) => `${n}`.padStart(4, "0")),
  [PlayType.RESULT_LOSS_4]: () =>
    generateNumbers(0, 99).map((n) => `${n}`.padStart(2, "0")),
  [PlayType.RESULT_LOSS_8]: () =>
    generateNumbers(0, 99).map((n) => `${n}`.padStart(2, "0")),
  [PlayType.RESULT_LOSS_10]: () =>
    generateNumbers(0, 99).map((n) => `${n}`.padStart(2, "0")),
};

export function shortenAccessKey(accessKey: string, chars = 4): string {
  return `${accessKey.substring(0, chars + 2)}...${accessKey.substring(
    accessKey.length - chars
  )}`;
}

export function winloseColor(v?: number) {
  return v ? (v > 0 ? "secondary" : "error") : "inherit"
}